import React, {useEffect, useState} from "react";


import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

// import { jsPDF } from "jspdf";


// import DayjsUtils from "@date-io/dayjs";



import axios from 'axios';

import {useSnackbar } from 'notistack5';
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";

import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import DesktopDatePicker from "@material-ui/lab/DesktopDatePicker";
import useScript from "../hooks/useScript";






export default function Home (props)    {

    const { enqueueSnackbar } = useSnackbar();
    // const { snackBar, enqueueSnackbar } = useState({})


    const [reportReady,setReportReady] = useState(false);
    const [report,setReport] = useState({});
    const [loading,setLoading] = useState(false);
    const [dob,setDob] = React.useState(null);

    const urlParams = window.location.search;

    useScript('/html2pdf.bundle.js',"head",true);



    const downloadPDF = ()=>{

        // const doc = new jsPDF(
        //     {
        //         orientation: "portrait",
        //         // format: [600, 1000],
        //     }
        // );

        // doc.html(document.body, {
        //     html2canvas: {
        //         scale: .5,
        //     },
        //     callback: function (doc) {
        //         doc.save("Report.pdf");
        //     },
        //     x: 1,
        //     y: 1,
        // }).then(()=>{
        //     console.log("done")
        // });


        const element = document.body;
        // eslint-disable-next-line no-undef
        html2pdf(element);



    };


    const getResults = (event)=>{

        event.preventDefault();

        //check if dob is incomplete
        if(isNaN(dob)){
            enqueueSnackbar("Invalid date of birth",{
                variant: 'error',
            });
            return;
        }



        setLoading(true);




        //check if there's r

        const reportNumber = new URLSearchParams(urlParams).get("r");
        if(reportNumber)
        {
            if(dob)
            {
                const year = dob.getFullYear();
                let month = dob.getMonth()+1;
                let dt = dob.getDate();

                if (dt < 10) {
                    dt = '0' + dt;
                }
                if (month < 10) {
                    month = '0' + month;
                }
                // console.log(dob);
                axios.post("https://us-central1-hometestbox-aad36.cloudfunctions.net/getResults",
                // axios.post("http://localhost:5001/hometestbox-aad36/us-central1/getResults",
                    {
                        "report":new URLSearchParams(urlParams).get("r"),
                        "dob":`${month}/${dt}/${year}`,
                        // "dob":dob.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit',timeZone:"GMT"}),
                    },
                ).then((res)=>{
                    setReport(res.data);
                    setReportReady(true);
                    // console.log(res.data)
                }).catch((err)=>{
                    // console.log(JSON.stringify(err.response.data.error))
                    enqueueSnackbar(err.response?.data?.error??"Unauthorized",{
                        variant: 'error',
                    })
                    setLoading(false);
                })
            }
            else
            {
                enqueueSnackbar("Invalid date of birth",{
                    variant: 'error',
                });

                setLoading(false);
            }

        }
        else
        {
            enqueueSnackbar("Invalid Link",{
                variant: 'error',
            });

            setLoading(false);

        }

    };






    if(urlParams)
    return(
       <>


           {!reportReady?(<>
               <form onSubmit={getResults}>

                <LinearProgress style={{display:loading?"":"none"}} />

                <Grid  container  spacing={2}  style={{margin:"1rem",maxWidth:"95%"}}>



                   <Grid item xs={12}>
                       <img src="logo.png" alt="Logo" style={{width:"10rem",height:"auto",marginLeft:"-20px"}}/>

                   </Grid>


                   <Grid item xs={12} style={{textAlign:"center"}}>
                       <Typography gutterBottom variant={"p"} style={{fontSize:"1rem"}}>
                           Please enter your date of birth below to view your results.
                       </Typography>
                   </Grid>



                       <Grid item md={5} xs={9} style={{textAlign:"center", marginLeft:"auto", marginRight:"auto"}} >

                           <DesktopDatePicker
                               style={{width:"100%"}}
                               inputVariant="outlined"
                               disableFuture
                               clearable
                               value={dob}
                               onChange={date=>{

                                       // console.log(date);
                                       // console.log("Before")
                                       // console.log(date.$d);
                                       // // move the time away from midnight, opposite direction of utc offset

                                       // const offset = -date.$d.getTimezoneOffset();
                                       // date.$d.setHours(Math.trunc(offset / 60), offset % 60);
                                       // date.$d.setDate(date.$d.getDate()+1)

                                       // //using  trunc() because there could be negative offsets, too.
                                       // console.log("After")
                                       // console.log(date.$d);

                                   // console.log(date)
                                   setDob(date)
                               }}
                               label="Date of birth"
                               maxDateMessage={"Please select a valid date for the Date of Birth."}
                               minDateMessage={"Please select a valid date for the Date of Birth."}
                               placeholder="MM/DD/YYYY"
                               format="MM/DD/YYYY"
                               renderInput={(params) => {return <TextField style={{width:"100%"}} {...params}
                                                                           InputProps={{
                                                                               ...params.InputProps,
                                                                               endAdornment: ""
                                                                           }}
                               />}}
                           />


                       </Grid>


                   <Grid item xs={12} style={{textAlign:"center", marginLeft:"auto", marginRight:"auto"}}>

                   <Button  type={"submit"} variant={"outlined"} disabled={loading}>
                       Get Results
                   </Button>

                   </Grid>


               </Grid>


               </form>
               </>):

               <Grid  container  spacing={2}  style={{margin:".5rem",maxWidth:"95%"}}>



                   <Grid item xs={12}>
                       <img src="logo.png" alt="Logo" style={{width:"10rem",height:"auto",marginLeft:"-20px"}}/>

                   </Grid>

               <Grid item xs={12}>
                  <Button variant={"outlined"} id={"printBtn"} style={{textAlign:"right",float:"right"}} onClick={downloadPDF}>
                      Print PDF
                  </Button>
               </Grid>


                   <Grid item xs={12}>
                       <Typography  variant={"h1"} style={{fontSize:"2rem"}}>
                           Result: {report.result.result}
                       </Typography>
                       <Divider/>
                   </Grid>


                   {(report.result.result.toLowerCase().includes("negative") || report.result.result.toLowerCase()==="not detected") &&
                   <Grid item xs={12}>
                       <Typography gutterBottom variant={"p"} style={{fontSize:"1rem"}}>
                           A Not Detected (negative) test result for this test means that SARS- CoV-2 RNA was not present in the specimen above the limit of detection. A negative result does not rule out the possibility of COVID-I9 and should not be used as the sole basis for treatment or patient management decisions. If COVID-19 is still suspected, based on exposure history together with other clinical findings, re-testing should be considered in consultation with public health authorities. Laboratory test results should always be considered in the context of clinical observations and epidemiological data in making a final diagnosis and patient management decisions.
                       </Typography
                       >
                   </Grid>}


                   {(report.result.result.toLowerCase()==="positive" || report.result.result.toLowerCase()==="detected") &&
                   <Grid item xs={12}>
                       <Typography gutterBottom variant={"p"} style={{fontSize:"1rem"}}>
                           Positive results are indicative of the presence of SARS-CoV-2 clinical correlation with patient history and other diagnostic information is necessary to determine patient infection status. Positive results do not rule out bacterial infection or co-infection with other viruses. Positive and negative predictive values of testing are highly dependent on prevalence. False positive test results are more likely when prevalence is moderate to low.
                       </Typography>
                   </Grid>}


                   <Grid item xs={12}>
                   <Typography  variant={"h1"} style={{fontSize:"2rem"}}>
                       Patient Information
                   </Typography>
                   <Divider/>
               </Grid>


               <Grid item xs={12}>
                   <Typography gutterBottom variant={"p"} style={{fontSize:"1rem"}}>
                       <b>Name:</b> {report.patient.fname + " " + report.patient.lname}<br/>
                       <b>Date of Birth:</b> {report.patient.dob}<br/>
                       <b>Gender:</b> {report.patient.gender}<br/>
                       <b>Phone:</b> {report.patient.phone}<br/>
                       {report.patient.idNumber && <> <b>ID #:</b> {report.patient.idNumber} <br/></>}
                   </Typography>
               </Grid>


               <Grid item xs={12}>
                   <Typography  variant={"h1"} style={{fontSize:"2rem"}}>
                       Specimen Information
                   </Typography>
                   <Divider/>
               </Grid>

               <Grid item xs={12}>
                   <Typography gutterBottom variant={"p"} style={{fontSize:"1rem"}}>
                       <b>Test:</b> {report.sample.test}<br/>
                       <b>Barcode:</b> {report.sample.barcode}<br/>
                       <b>Source:</b> {report.sample.source}<br/>
                       <b>Date collected:</b> {new Date(report.sample.collectionTime._seconds *1000).toLocaleString() }<br/>
                       <b>Date reported:</b> {new Date(report.sample.reportTime._seconds *1000).toLocaleString() }<br/>
                   </Typography>
               </Grid>




               <Grid item xs={12}>
                   <Typography  variant={"h1"} style={{fontSize:"2rem"}}>
                       Test Site
                   </Typography>
                   <Divider/>
               </Grid>


               <Grid item xs={12}>
                   <Typography gutterBottom variant={"p"} style={{fontSize:"1rem"}}>
                       <b>Facility:</b> {report.sample.facility}<br/>
                       <b>Doctor:</b> {report.result.doctor}<br/>
                   </Typography>
               </Grid>


                   <Grid item xs={12}>
                       <Typography  variant={"h1"} style={{fontSize:"2rem"}}>
                           Lab Information
                       </Typography>
                       <Divider/>
                   </Grid>

                   <Grid item xs={12}>
                       <Typography gutterBottom variant={"p"} style={{fontSize:"1rem"}}>
                           <b>Lab:</b> {report.result.lab}<br/>
                           <b>Lab Phone Number:</b> {report.result.labPhone} <br/>
                           <b>Lab Address:</b> {report.result.labAddress} <br/>
                           {/*<b>Lab CLIA:</b> {report.result.labCLIA} <br/>*/}
                           <b>Lab Authorized Official:</b> {report.result.labAuthorizedOfficial} <br/>
                       </Typography>
                   </Grid>





                   <Grid item xs={12}>
                       <Divider/>
                   <Typography gutterBottom variant={"p"} style={{fontSize:".8rem"}}>
                       The <a href={"https://www.fda.gov/media/136869/download"} target={"_blank"} rel={"noreferrer"}> Fact Sheet for Healthcare Providers: iAMP COVID-19 Detection Kit</a> and <a href={"https://www.fda.gov/media/136871/download"} target={"_blank"} rel={"noreferrer"}> Fact Sheet for Patients: iAMP COVID-19 Detection Kit</a> are available on the FDA website.
                   </Typography>
               </Grid>




           </Grid>}
       </>
    )
    else
        return (
            <>
                <Grid container>
                    <Grid item style={{textAlign:"center",width:"100%",marginTop:"50%"}}>
                        <Typography>
                            Invalid Link
                        </Typography>
                        <img src="logo.png" alt="Logo" style={{width:"200px",marginLeft:"-20px"}}/>

                    </Grid>
                </Grid>
           </>
        )
}
