import React from "react";
import Home from "./Components/Home";

import { SnackbarProvider, useSnackbar } from 'notistack5';
import { StyledEngineProvider } from '@material-ui/core/styles';
import { createTheme, ThemeProvider, styled } from '@material-ui/core/styles';
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';




function App() {

  const theme = createTheme();

  return (

      <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
              <StyledEngineProvider injectFirst>
                  <SnackbarProvider maxSnack={3}>
                    <div>
                         <Home/>
                    </div>
                  </SnackbarProvider>
              </StyledEngineProvider>
          </ThemeProvider>
      </LocalizationProvider>
  );
}

export default App;
